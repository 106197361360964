@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Tajawal:wght@200;300;400;500;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
:root {
  --primary-color: #003a74;
  --secondary-color: #4271ff;
  --text-color: #555555;
  --blur-text-color: "#737373";
  --greey-color: #f5f5f5;
  --bg-color: #404c60;
  --sucsess-color: #00bf36;
  --failed-color: #ff2222;
  --border-color: #ededed;
  --button-bg-color: linear-gradient(45deg, #1d68df 0%, #23dff8 100%);
  --border_redius: 8px;
  --large-border-redius: 16px;
  --primary-font: "Tajawal", sans-serif;
}
body {
  box-sizing: border-box;
  line-height: 1.5;
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.3s ease;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
  font-family: var(--primary-font);
  color: var(--text-color);
  direction: rtl;
}
* {
  background-size: cover;
  background-repeat: no-repeat;
  /* word-wrap: break-word; */
  hyphens: auto;
  /* word-break: break-all; */
}
html {
  scroll-behavior: smooth;
}
main {
  width: 100%;
  /* height: 100vh; */
  min-height: 100vh;
  padding-bottom: 100px;
  padding-right: 290px;
  padding-left: 30px;
  padding-top: 20px;
  overflow-x: hidden;
  background-color: var(--greey-color);
  /* overflow: scroll; */
}

@media (max-width: 768px) {
  main {
    padding-left: 15px;
    padding-right: 110px;
    /* padding-top: 48px; */
  }
}
a {
  text-decoration: none;
  color: inherit;
  line-height: 36px;
}
.nolist_style {
  padding: 0;
  margin: 0;
  list-style: none !important;
}
h1,
.h1 {
  font-size: 44px;
  font-family: var(--primary-font);
  margin-bottom: 20px;
  font-weight: 700;
  color: var(--text-color);
}
h2,
.h2 {
  font-size: 40px;
  color: var(--text-color);
}
h3,
.h3 {
  font-size: 20px;
  color: var(--text-color);
}
h4,
.h4 {
  font-size: 18px;
  color: var(--text-color);
}
h5,
.h5 {
  font-size: 16px;
}
h6,
.h6 {
  font-size: 14px;
}
@media only screen and (max-width: 768px) {
  h1,
.h1 {
  font-size: 31px;
  font-family: var(--primary-font);
  margin-bottom: 20px;
  font-weight: 700;
  color: var(--text-color);
}
h2,
.h2 {
  font-size: 27px;
  color: var(--text-color);
}
h3,
.h3 {
  font-size: 15px;
  color: var(--text-color);
}
h4,
.h4 {
  font-size: 11px;
  color: var(--text-color);
}
h5,
.h5 {
  font-size: 9px;
}
h6,
.h6 {
  font-size: 8px;
}
}
@media only screen and (max-width: 450px) {
  h1,
.h1 {
  font-size: 25px;
  font-family: var(--primary-font);
  margin-bottom: 20px;
  font-weight: 700;
  color: var(--text-color);
}
h2,
.h2 {
  font-size: 21px;
  color: var(--text-color);
}
h3,
.h3 {
  font-size: 11px;
  color: var(--text-color);
}
h4,
.h4 {
  font-size: 9px;
  color: var(--text-color);
}
h5,
.h5 {
  font-size: 8px;
}
h6,
.h6 {
  font-size: 7px;
}
}

.hilight_text {
  color: var(--primary-color);
}
label {
  margin-bottom: 7px;
}
input,
textarea,
.input,
select {
  border: solid 2px var(--border-color);
  border-radius: var(--border_redius);
  height: 42px;
  padding: 2px 5px;
  color: var(--text-color);
  font-weight: 400;
  font-size: 16px;
}
input:focus,
textarea:focus,
.input:focus,
select:focus,
select:focus-within {
  border: solid 1px var(--primary-color);
}
button:disabled {
  opacity: 0.5;
}

input:focus-visible,
textarea:focus-visible {
  outline: none;
}

textarea::-webkit-resizer,
input::-webkit-inner-spin-button {
  display: none;
}
/* *::-webkit-scrollbar {
  display: none;
} */
/* scaleable */
@keyframes loaderAnimation {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}
.scaleAble {
  animation: loaderAnimation 1.5s linear infinite;
  background: radial-gradient(rgb(243, 240, 240), var(--greey-color));
  opacity: 0.3;
  transition: opacity 2s;
}
.input_scaleAble {
  animation: loaderAnimation 1.5s linear infinite;
  background: radial-gradient(white, rgb(248, 246, 246));
  opacity: 0.3;
  transition: opacity 2s;
}
.table_container {
  min-width: 290px;
  width: 100%;
}
.table_container table{
  min-width: 100%;
}
